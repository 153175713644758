import { Sanity$Banner } from '../../types/sanityTypes';

const CHARACTERS_PER_LINE = 35;

function calculateBannerCharacters(banner: Sanity$Banner) {
  if (!banner.content) {
    return 0;
  }
  const textEntries = banner.content
    .map((textBlock) => textBlock.children)
    .flatMap((children) => children.map((child) => child.text));

  const numCharacters = textEntries.reduce(
    (cur, textEntry) => cur + textEntry.length,
    0,
  );
  return numCharacters;
}

export function getEstimatedPaddingClassName(banner: Sanity$Banner) {
  if (!banner) {
    // We know whether we have a Sanity$Banner during server side render, but we
    // don't know whether we have a client side banner (such as the welcome back banner).
    // Return a padding that's enough for client side banners.
    return 'pt-[12vh] lg:pt-[18vh]';
  }
  const numCharacters = calculateBannerCharacters(banner);
  const numLines = Math.round(numCharacters / CHARACTERS_PER_LINE);
  if (numLines > 4) {
    return 'pt-36';
  }
  if (numLines >= 3) {
    return 'pt-32';
  }

  return 'pt-28';
}

export function getEstimatedProductPagePadding(banner: Sanity$Banner) {
  if (!banner) {
    return '';
  }
  const numCharacters = calculateBannerCharacters(banner);
  const numLines = Math.round(numCharacters / CHARACTERS_PER_LINE);

  if (numLines >= 4) {
    return 'pt-28 md:mt-4 md:pt-10';
  }

  return 'pt-12 xl:mt-8';
}
