import { FormattedMessage as T } from 'react-intl';
import TrustPilotLogo from './trustpilot-logo.svg';
import { getLabel } from './getLabel';
import TrustPilotStars from './TrustPilotStars';

type TrustPilotRatingProps = {
  rating: number;
  url: string;
  shortText?: string;
};

const TrustPilotRating = ({
  rating,
  url,
  shortText,
}: TrustPilotRatingProps) => {
  return (
    <div className="m-auto flex w-min flex-col gap-2 rounded-full bg-white px-10 py-3 text-lg md:text-xl min-[800px]:p-0 lg:m-0 lg:w-full">
      <a
        href={url}
        className="flex items-center justify-center gap-3 min-[800px]:px-0 lg:justify-start"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="text-sm font-[500] min-[800px]:hidden lg:block lg:text-lg">
          <T {...getLabel(rating)} />
        </span>
        <TrustPilotStars rating={rating} />
        <span className="-mt-1">
          <TrustPilotLogo />
        </span>
      </a>
      {shortText && (
        <a
          href={url}
          className="text-center text-xs text-blue-100/60 min-[800px]:hidden lg:block lg:text-left lg:text-base"
          target="_blank"
          rel="noopener noreferrer"
        >
          {shortText}
        </a>
      )}
    </div>
  );
};

export default TrustPilotRating;
