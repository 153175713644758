import { cn } from '@otovo/shared/lib/classNames';
import {
  Sanity$LogoItem,
  Sanity$HeroLogos,
} from '@otovo/shared/types/sanityTypes';
import SanityImage from '@otovo/shared/components/Sanity/SanityImage';

const Wrapper = ({
  logo,
  children,
}: {
  logo: Sanity$LogoItem;
  children: React.ReactNode;
}) => {
  const rel = logo.rel ? `${logo.rel} noreferrer` : 'noreferrer';
  const target = '_blank';
  const className = cn(
    'flex rounded-lg transition-all group',
    'focus:outline-blue-70 focus:outline focus:outline-2 focus:outline-offset-1',
    'hover:outline-blue-70 hover:-translate-y-1 hover:scale-[1.03] hover:outline hover:outline-offset-1',
    'hover:bg-[#FAFAFF] focus:bg-[#FAFAFF]',
    'md:hover:bg-transparent md:focus:bg-transparent',
  );

  return (
    <a
      key={logo._key}
      href={logo.url}
      target={target}
      rel={rel}
      className={logo.url ? className : ''}
    >
      {children}
    </a>
  );
};

const HeroLogos = ({ heading, logos }: Sanity$HeroLogos) => {
  return (
    <div className="block md:mt-[5vh] xl:mt-[7vh]">
      {heading && (
        <h3 className="text-md text-lilac-20 md:text-black">{heading}</h3>
      )}
      <div
        className={cn(
          'mt-2 md:my-2 lg:my-4 xl:w-1/2',
          `grid grid-cols-3 gap-3 md:grid-cols-2 xl:grid-cols-3`,
        )}
      >
        {logos.map((logo) => {
          return (
            <Wrapper key={logo._key} logo={logo}>
              <div className="align-center flex h-16 w-full justify-center 2xl:h-24">
                <SanityImage
                  image={logo.image}
                  alt={logo.title}
                  sizes="200px"
                  className={cn(
                    'brightness-0 invert transition-all',
                    'group-hover:brightness-100 group-hover:invert-0',
                    'group-focus:brightness-100 group-focus:invert-0',
                    'md:brightness-100 md:grayscale md:invert-0',
                    'md:group-hover:brightness-100 md:group-hover:grayscale-0',
                    'md:group-focus:brightness-100 md:group-focus:grayscale-0',
                  )}
                />
              </div>
            </Wrapper>
          );
        })}
      </div>
    </div>
  );
};

export default HeroLogos;
