import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'TrustPilot';

export default defineMessages({
  bad: {
    id: `${I18N_NAMESPACE}.bad`,
    defaultMessage: 'Bad',
  },
  poor: {
    id: `${I18N_NAMESPACE}.poor`,
    defaultMessage: 'Poor',
  },
  average: {
    id: `${I18N_NAMESPACE}.average`,
    defaultMessage: 'Average',
  },
  great: {
    id: `${I18N_NAMESPACE}.great`,
    defaultMessage: 'Great',
  },
  excellent: {
    id: `${I18N_NAMESPACE}.excellent`,
    defaultMessage: 'Excellent',
  },
});
