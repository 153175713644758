import { GoogleRatingProps } from './GoogleRating';

function between(x: number, min: number, max: number) {
  return x > min && x < max;
}

export const GoogleStars = ({ rating }: Omit<GoogleRatingProps, 'url'>) => {
  const gray = '#dadce0';
  const yellow = '#fbbd05';

  const numberOfStars = 5;
  const startPos = 28; // from the original Google SVG
  const spaceBetweenStars = 72; // from the original Google SVG

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="1em"
      width="4em"
      viewBox="0 0 346 72"
    >
      {new Array(numberOfStars).fill(0).map((_pos, index) => {
        const moveTo = `M${startPos + index * spaceBetweenStars}`;
        return (
          <g key={moveTo}>
            <path
              d={`${moveTo}.443,5.58c0.221,-0.467 0.691,-0.764 1.208,-0.764c0.516,-0 0.986,0.297 1.207,0.764l7.439,15.709l17.24,2.221c0.512,0.066 0.94,0.421 1.1,0.912c0.159,0.491 0.021,1.03 -0.354,1.385l-12.642,11.929l3.216,17.082c0.095,0.507 -0.11,1.025 -0.528,1.328c-0.418,0.304 -0.973,0.339 -1.426,0.092l-15.252,-8.337l-15.252,8.337c-0.454,0.247 -1.009,0.212 -1.427,-0.092c-0.417,-0.303 -0.623,-0.821 -0.527,-1.328l3.215,-17.082l-12.642,-11.929c-0.375,-0.355 -0.513,-0.894 -0.353,-1.385c0.159,-0.491 0.587,-0.846 1.1,-0.912l17.239,-2.221l7.439,-15.709Z`}
              fill={Math.floor(rating) >= index + 1 ? yellow : gray}
            />
            {between(rating, index, index + 1) && (
              <path
                d={`${moveTo}.945,4.816l-0,43.085l-15.252,8.337c-0.453,0.247 -1.009,0.212 -1.426,-0.092c-0.418,-0.303 -0.624,-0.821 -0.528,-1.328l3.216,-17.082l-12.642,-11.929c-0.376,-0.355 -0.514,-0.894 -0.354,-1.385c0.16,-0.491 0.588,-0.846 1.1,-0.912l17.239,-2.221l7.439,-15.709c0.221,-0.467 0.692,-0.764 1.208,-0.764Z`}
                fill={yellow}
              />
            )}
          </g>
        );
      })}
    </svg>
  );
};
