import { getEstimatedPaddingClassName } from '@otovo/shared/components/NotificationBanner/estimateRequiredPadding';
import { SHOW_HERO_LOGOS_STOREFRONT } from '@otovo/shared/config/featureToggles/constants';
import { isEnabled } from '@otovo/shared/config/featureToggles/utils';
import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import { cn } from '@otovo/shared/lib/classNames';
import {
  Sanity$CampaignPage,
  Sanity$Section,
} from '@otovo/shared/types/sanityTypes';
import ContentRowList from '../../ContentRowList';
import DiagonalSpacer from './DiagonalSpacer';
import HeroHeading from './HeroHeading';
import HeroLead from './HeroLead';
import HeroLogos from './HeroLogos';
import ResponsiveHero from './ResponsiveHero/ResponsiveHero';
import TrustVendor from './TrustVendor';

type Props = {
  page: Sanity$CampaignPage;
  addressBarRow: Sanity$Section[];
  isBannerShown: boolean;
};

const HeroSection = ({ page, addressBarRow, isBannerShown }: Props) => {
  const config = useGlobalConfig();
  const padding = getEstimatedPaddingClassName(
    isBannerShown ? page.banner : undefined,
  );

  const { mainHardwareType } = page;
  const rowContext = {
    mainHardwareType,
  };

  const isShowHeroLogosEnabled = isEnabled(SHOW_HERO_LOGOS_STOREFRONT, config);

  return (
    <div
      id="heroSection"
      className={cn(
        `relative grid grid-cols-1 md:grid-cols-[_1fr_256px_1fr]`,
        'md:grid-rows-[minmax(640px,90vh)]',
      )}
    >
      <div className="z-[2] md:col-start-1 md:col-end-3 md:row-start-1 md:row-end-2">
        <DiagonalSpacer />
        <div
          className={cn(
            padding,
            'pl-4 sm:pl-8 md:pl-6 lg:pl-16 xl:pl-24',
            'pr-4 sm:pr-8 md:pr-0',
          )}
        >
          <HeroHeading>{page.headline}</HeroHeading>

          {!!page.lead && <HeroLead>{page.lead}</HeroLead>}
          <div className="mt-[5vh]">
            <ContentRowList rows={addressBarRow} rowContext={rowContext} />
            {page.heroLogos && isShowHeroLogosEnabled && (
              <div className="hidden md:block">
                <HeroLogos {...page.heroLogos} />
              </div>
            )}
          </div>
          {!!page.trustVendor && (
            <div className="hidden md:block">
              <TrustVendor {...page.trustVendor} />
            </div>
          )}
        </div>
      </div>

      <ResponsiveHero
        image={page.mainImage}
        sizes={[
          { width: 1500, height: 1200, breakpoint: '110em' },
          { width: 1200, height: 950, breakpoint: '90em' },
          { width: 900, height: 900, breakpoint: '80em' },
          { width: 800, height: 800, breakpoint: '60em' },
          { width: 650, height: 650, breakpoint: '50em' },
          { width: 800, height: 800, breakpoint: '40em' },
          { width: 650, height: 650, breakpoint: '30em' },
          { width: 500, height: 500, breakpoint: '0em' },
        ]}
      >
        {!!page.trustVendor && (
          <div className="absolute top-[104px] block w-full md:hidden">
            <TrustVendor {...page.trustVendor} />
          </div>
        )}
      </ResponsiveHero>

      {page.heroLogos && isShowHeroLogosEnabled && (
        <div className="absolute bottom-5 mx-4 w-[calc(100%-2rem)] rounded-lg bg-grey-90 p-4 md:hidden">
          <HeroLogos {...page.heroLogos} />
        </div>
      )}
    </div>
  );
};

export default HeroSection;
