import { ReactNode } from 'react';
import { Rainbow$Props } from '@otovo/shared/types/rainbow';
import { Sanity$Image } from '@otovo/shared/types/sanityImage';

import { cn } from '@otovo/shared/lib/classNames';
import SanityImage from '@otovo/shared/components/Sanity/SanityImage';
import { getSrcSet } from './utils';

type BreakPointType = {
  height: number;
  width: number;
  breakpoint: string;
};
type Props = {
  image: Sanity$Image;
  sizes: Array<BreakPointType>;
  children: ReactNode;
} & Rainbow$Props;

const ResponsiveHero = ({ sizes, image, children }: Props) => {
  if (!image?.asset) {
    return null;
  }

  if (!Array.isArray(sizes) || !sizes.length) {
    throw new Error('Component expects prop Sizes[] with at least one element');
  }

  const smallestImageWidth = sizes[sizes.length - 1].width;
  const smallestImageHeight = sizes[sizes.length - 1].height;
  const heroImageAltText = image.alt || 'solar panels on roof';

  return (
    <div
      data-testid="responsive-hero"
      className={cn(
        'relative flex',
        'md:clip-hero-image md:col-start-2 md:col-end-4 md:row-start-1 md:row-end-2',
      )}
    >
      <picture className="w-full">
        {sizes.map((size) => (
          <source
            data-testid="responsive-hero-image-source"
            key={`image-${size.breakpoint}`}
            srcSet={getSrcSet(image, size.width, size.height)}
            media={`(min-width: ${size.breakpoint})`}
          />
        ))}
        <SanityImage
          image={image}
          alt={heroImageAltText}
          // We set the height and width explicitly to avoid layout shifts. Ideally we would
          // set height and width on every "source" element above instead. However, doing so
          // has poor browser support (only chrome as of nov 2021). As a stop gap measure,
          // we use the same aspect ratio for all the images 🤷‍♂️
          height={smallestImageHeight}
          width={smallestImageWidth}
          priority
          blurDataURL={image.asset.metadata.lqip}
          className="relative h-full max-h-full min-h-0 w-full min-w-0 max-w-full flex-auto rounded-none object-cover"
        />
      </picture>
      {children}
    </div>
  );
};

export default ResponsiveHero;
