import cx from 'classnames';

const DiagonalSpacer = () => {
  return (
    <div
      className={cx(
        'float-right h-full md:block',
        `md:w-72 lg:w-80`,
        'md:[shape-outside:polygon(256px_0,100%_0%,48px_100%,0%_100%)]',
        'lg:[shape-outside:polygon(256px_0,100%_0%,128px_100%,0%_100%)]',
      )}
    />
  );
};

export default DiagonalSpacer;
