import { getSanityClientConfig } from '@otovo/shared/lib/sanity/sanityClient';
import { Sanity$Image } from '@otovo/shared/types/sanityImage';
import imageUrlBuilder from '@sanity/image-url';

const IMAGE_QUALITY = 74;

export function getSrc(image: Sanity$Image): any {
  const urlBuilder = imageUrlBuilder(getSanityClientConfig());
  return urlBuilder.image(image);
}

export function getSrcSet(
  image: Sanity$Image,
  width: number,
  height: number,
): string {
  return getSrc(image)
    .width(width)
    .height(height)
    .fit('clip')
    .quality(IMAGE_QUALITY)
    .auto('format');
}
