import { Sanity$TrustVendor } from '@otovo/shared/types/sanityTypes';

import TrustPilotRating from '../../../TrustPilot/TrustPilotRating';
import GoogleRating from '../../../GoogleRating/GoogleRating';

const vendors = Object.freeze({
  TRUSTPILOT: 'trustpilot',
  GOOGLE: 'google',
});

type TrustVendorWrapperProps = {
  children: React.ReactNode;
  shortText?: string;
};

const TrustVendorWrapper = ({
  children,
  shortText,
}: TrustVendorWrapperProps) => {
  const bottom = shortText ? '-bottom-8' : 'bottom-5';

  return (
    <div
      className={`${bottom} absolute left-0 right-0 min-[800px]:bottom-14 min-[800px]:left-auto min-[800px]:right-auto lg:bottom-14 lg:left-auto lg:right-auto`}
    >
      {children}
    </div>
  );
};

const TrustVendor = ({ vendor, score, url, shortText }: Sanity$TrustVendor) => {
  switch (vendor) {
    case vendors.TRUSTPILOT:
      return (
        <TrustVendorWrapper shortText={shortText}>
          <TrustPilotRating rating={score} url={url} shortText={shortText} />
        </TrustVendorWrapper>
      );
    case vendors.GOOGLE:
      return (
        <TrustVendorWrapper shortText={shortText}>
          <GoogleRating rating={score} url={url} shortText={shortText} />
        </TrustVendorWrapper>
      );
    default:
      return null;
  }
};

export default TrustVendor;
