import m from './messages';

export const getLabel = (rating: number) => {
  if (typeof rating !== 'number') {
    throw new Error('Trustpilot rating must be a number');
  }

  if (rating <= 1.2) {
    return m.bad;
  }
  if (rating > 1.2 && rating <= 1.7) {
    return m.bad;
  }
  if (rating > 1.7 && rating <= 2.2) {
    return m.poor;
  }
  if (rating > 2.2 && rating <= 2.7) {
    return m.poor;
  }
  if (rating > 2.7 && rating <= 3.2) {
    return m.average;
  }
  if (rating > 3.2 && rating <= 3.7) {
    return m.average;
  }
  if (rating > 3.7 && rating <= 4.2) {
    return m.great;
  }
  if (rating > 4.2 && rating <= 4.7) {
    return m.excellent;
  }
  return m.excellent;
};
