import { GoogleStars } from './GoogleStars';
import GoogleLogo from './googleLogo.svg';

export type GoogleRatingProps = {
  rating: number;
  url: string;
  shortText?: string;
};

const GoogleRating = ({ rating, url, shortText }: GoogleRatingProps) => {
  return (
    <div className="m-auto flex w-min flex-col gap-2 rounded-full bg-white px-10 py-3 min-[800px]:p-0 lg:m-0 lg:w-full">
      <a
        href={url}
        className="flex justify-center gap-3 text-lg min-[800px]:px-0 min-[800px]:text-2xl lg:justify-start lg:text-3xl"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GoogleLogo />
        <GoogleStars rating={rating} />
      </a>
      {shortText && (
        <a
          href={url}
          className="text-center text-xs text-blue-100/60 min-[800px]:hidden lg:block lg:text-left lg:text-base"
          target="_blank"
          rel="noopener noreferrer"
        >
          {shortText}
        </a>
      )}
    </div>
  );
};

export default GoogleRating;
