type FillState = 'green' | 'gray' | 'partial';

type PathProps = {
  startPos: number;
  state: FillState;
};

const boxWidth = 96;
const trustpilotColor = {
  green: '#00b67a',
  gray: '#dcdce6',
};

function getFillState(rating, index): FillState {
  if (rating > index && rating < index + 1) {
    return 'partial';
  }
  if (rating > index) {
    return 'green';
  }
  return 'gray';
}

const SquareBackground = ({ startPos, state }: PathProps) => {
  const isPartial = state === 'partial';
  const firstHalfWidth = boxWidth / 2;

  return (
    <>
      <path
        d={`m${startPos} 0h${isPartial ? firstHalfWidth : boxWidth}v96h-${
          isPartial ? firstHalfWidth : boxWidth
        }z`}
        fill={trustpilotColor[isPartial ? 'green' : state]}
      />
      {isPartial && (
        <path
          d={`m${
            startPos + firstHalfWidth
          } 0h${firstHalfWidth}v96h-${firstHalfWidth}z`}
          fill={trustpilotColor.gray}
        />
      )}
    </>
  );
};

const TrustPilotStars = ({ rating }: { rating: number }) => {
  const numberOfStars = 5;
  const spaceBetweenStars = 104;

  return (
    <svg
      height="1em"
      width="5.33em"
      viewBox="0 0 512 96"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        {new Array(numberOfStars).fill(0).map((_el, index) => (
          <SquareBackground
            key={`bg-${index * spaceBetweenStars}`}
            startPos={index * spaceBetweenStars}
            state={getFillState(rating, index)}
          />
        ))}
        <g fill="#fff">
          <path d="m48 64.7 14.6-3.7 6.1 18.8zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2z" />
          <path d="m152 64.7 14.6-3.7 6.1 18.8zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2z" />
          <path d="m256 64.7 14.6-3.7 6.1 18.8zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2z" />
          <path d="m360 64.7 14.6-3.7 6.1 18.8zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2z" />
          <path d="m464 64.7 14.6-3.7 6.1 18.8zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2z" />
        </g>
      </g>
    </svg>
  );
};

export default TrustPilotStars;
